const ChatSVG: React.FC = (props) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 21.7803H3.5C2.81 21.7803 2.25 21.2203 2.25 20.5303V20.1303C2.25 18.5703 3.52 17.2903 5.08 17.2803H8.4C9.97 17.2903 11.25 18.5703 11.25 20.1303V20.5303C11.25 21.2203 10.69 21.7803 10 21.7803ZM3.75 20.2803H9.75V20.1303C9.75 19.3903 9.14 18.7903 8.39 18.7803H5.09C4.35 18.7803 3.75 19.3903 3.75 20.1303V20.2803Z"
        fill="#141414"
      />
      <path
        d="M6.75025 16.2803C6.01025 16.2803 5.32025 15.9903 4.80025 15.4703C4.28025 14.9703 3.99025 14.2503 4.00025 13.5103C4.00025 12.0003 5.24025 10.7803 6.75025 10.7803C8.26025 10.7803 9.50025 12.0103 9.50025 13.5303C9.50025 15.0503 8.27025 16.2803 6.75025 16.2803ZM6.75025 12.2803C6.06025 12.2803 5.50025 12.8403 5.50025 13.5203C5.50025 13.8703 5.62025 14.1703 5.85025 14.4003C6.10025 14.6503 6.41025 14.7803 6.75025 14.7803C7.44025 14.7803 8.00025 14.2203 8.00025 13.5303C8.00025 12.8403 7.44025 12.2803 6.75025 12.2803Z"
        fill="#141414"
      />
      <path
        d="M13 14.7803C12.89 14.7803 12.77 14.7503 12.66 14.7003C12.41 14.5703 12.25 14.3103 12.25 14.0303V11.7803H12C10.48 11.7803 9.25 10.5503 9.25 9.03027V5.03027C9.25 3.51027 10.48 2.28027 12 2.28027H20C21.52 2.28027 22.75 3.51027 22.75 5.03027V9.03027C22.75 10.5503 21.52 11.7803 20 11.7803H17.25L13.45 14.6303C13.32 14.7303 13.16 14.7803 13 14.7803ZM12 3.78027C11.3 3.78027 10.75 4.33027 10.75 5.03027V9.03027C10.75 9.72027 11.31 10.2803 12 10.2803H13C13.41 10.2803 13.75 10.6203 13.75 11.0303V12.5303L16.55 10.4303C16.68 10.3303 16.84 10.2803 17 10.2803H20C20.69 10.2803 21.25 9.72027 21.25 9.03027V5.03027C21.25 4.33027 20.7 3.78027 20 3.78027H12Z"
        fill="#141414"
      />
      <path
        d="M18.6105 7.82995C18.4105 7.82995 18.1805 7.73995 18.0405 7.59995C17.9005 7.45995 17.8105 7.22995 17.8105 7.02995C17.8105 6.82995 17.9005 6.60995 18.0405 6.46995C18.1805 6.32995 18.4005 6.22995 18.6105 6.23995C18.8105 6.23995 19.0405 6.33995 19.1805 6.47995C19.4705 6.76995 19.4605 7.30995 19.1805 7.59995C19.0405 7.73995 18.8205 7.82995 18.6205 7.82995H18.6105Z"
        fill="#141414"
      />
      <path
        d="M16.0102 7.81994C15.8002 7.81994 15.5902 7.74994 15.4402 7.59994C15.2902 7.44994 15.2002 7.22994 15.2002 7.02994C15.2002 6.82994 15.3002 6.60994 15.4402 6.46994C15.7302 6.17994 16.2802 6.17994 16.5702 6.46994C16.8602 6.75994 16.8602 7.30994 16.5702 7.59994C16.4202 7.74994 16.2102 7.81994 16.0002 7.81994H16.0102Z"
        fill="#141414"
      />
      <path
        d="M13.3896 7.81994C13.1796 7.81994 12.9696 7.74994 12.8296 7.59994C12.6896 7.44994 12.5996 7.22994 12.5996 7.02994C12.5996 6.82994 12.6896 6.60994 12.8296 6.46994C13.1196 6.17994 13.6696 6.17994 13.9596 6.46994C14.2496 6.75994 14.2496 7.30994 13.9596 7.59994C13.8096 7.74994 13.5996 7.81994 13.3896 7.81994Z"
        fill="#141414"
      />
    </svg>
  );
};

export { ChatSVG };
