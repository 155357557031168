const HelpSVG: React.FC = (props) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M13.4369 9.7748C13.3771 9.02668 12.731 8.46004 11.9786 8.50171C11.9545 8.50305 11.9302 8.50322 11.9061 8.50221C11.219 8.4737 10.6088 8.93816 10.4534 9.60804C10.3598 10.0115 9.95681 10.2627 9.55331 10.1691C9.14982 10.0755 8.89861 9.67251 8.99223 9.26902C9.30792 7.90843 10.5392 6.96148 11.932 7.00222C13.5001 6.93477 14.8379 8.13525 14.9357 9.70625C14.9367 9.72177 14.9372 9.73731 14.9372 9.75286C14.9372 10.8984 14.1022 11.6035 13.5853 12.0238C13.5172 12.0791 13.4539 12.1299 13.3948 12.1773C13.1908 12.341 13.0382 12.4635 12.915 12.5923C12.8181 12.6934 12.7927 12.7462 12.7871 12.7616C12.7824 13.1718 12.4485 13.5029 12.0372 13.5029C11.6229 13.5029 11.2872 13.1671 11.2872 12.7529C11.2872 12.2148 11.5806 11.8171 11.8312 11.5552C12.03 11.3474 12.2854 11.1433 12.4996 10.972C12.5486 10.9328 12.5955 10.8953 12.639 10.8599C13.1857 10.4154 13.4272 10.1234 13.4369 9.7748Z"
        fill="#141414"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.6263 14.9138C12.489 14.7397 12.2761 14.6279 12.0372 14.6279C11.5539 14.6279 11.1621 15.0197 11.1621 15.503C11.1621 15.9863 11.5539 16.378 12.0372 16.378C12.5204 16.378 12.9122 15.9863 12.9122 15.503C12.9122 15.264 12.8004 15.0511 12.6263 14.9138Z"
        fill="#141414"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M17.8302 6.14213C14.8001 3.11202 9.95645 2.90086 6.67414 5.65578C3.39182 8.4107 2.75995 13.2176 5.21878 16.7272C5.33954 16.8996 5.38232 17.1148 5.33667 17.3202L4.96085 19.0115L6.6521 18.6357C6.85757 18.59 7.07276 18.6328 7.24514 18.7536C10.7547 21.2124 15.5616 20.5805 18.3165 17.2982C21.0715 14.0159 20.8603 9.17224 17.8302 6.14213ZM5.70981 4.50684C9.58791 1.25186 15.3107 1.50135 18.8909 5.08147C22.471 8.66159 22.7205 14.3844 19.4655 18.2625C16.2827 22.0546 10.7818 22.8525 6.66252 20.1699L4.13575 20.7314C3.88543 20.787 3.62404 20.7109 3.44272 20.5296C3.26141 20.3483 3.18529 20.0869 3.24091 19.8366L3.80239 17.3098C1.11978 13.1905 1.9177 7.68964 5.70981 4.50684Z"
        fill="#141414"
        fillRule="evenodd"
      />
    </svg>
  );
};

export { HelpSVG };
