const GraphicsSVG: React.FC = (props) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M2.25 8C2.25 7.58579 2.58579 7.25 3 7.25H21C21.4142 7.25 21.75 7.58579 21.75 8C21.75 8.41421 21.4142 8.75 21 8.75H3C2.58579 8.75 2.25 8.41421 2.25 8Z"
        fill="#141414"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.5 14.77C7.91421 14.77 8.25 15.1058 8.25 15.52V17C8.25 17.4142 7.91421 17.75 7.5 17.75C7.08579 17.75 6.75 17.4142 6.75 17V15.52C6.75 15.1058 7.08579 14.77 7.5 14.77Z"
        fill="#141414"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.5 11.5C10.9142 11.5 11.25 11.8358 11.25 12.25V17C11.25 17.4142 10.9142 17.75 10.5 17.75C10.0858 17.75 9.75 17.4142 9.75 17V12.25C9.75 11.8358 10.0858 11.5 10.5 11.5Z"
        fill="#141414"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M13.5 14.1797C13.9142 14.1797 14.25 14.5155 14.25 14.9297V16.9997C14.25 17.4139 13.9142 17.7497 13.5 17.7497C13.0858 17.7497 12.75 17.4139 12.75 16.9997V14.9297C12.75 14.5155 13.0858 14.1797 13.5 14.1797Z"
        fill="#141414"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16.5 12.0498C16.9142 12.0498 17.25 12.3856 17.25 12.7998V16.9998C17.25 17.414 16.9142 17.7498 16.5 17.7498C16.0858 17.7498 15.75 17.414 15.75 16.9998V12.7998C15.75 12.3856 16.0858 12.0498 16.5 12.0498Z"
        fill="#141414"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.0883 4.77851C11.0182 4.75681 10.9436 4.74512 10.8663 4.74512C10.789 4.74512 10.7145 4.75681 10.6443 4.77851C10.5357 4.81175 10.4269 4.87162 10.3324 4.96616C10.1719 5.12662 10.1113 5.32818 10.1113 5.50012C10.1113 5.67205 10.1719 5.87361 10.3324 6.03407C10.4928 6.19453 10.6944 6.25512 10.8663 6.25512C11.0383 6.25512 11.2398 6.19453 11.4003 6.03407C11.5607 5.87361 11.6213 5.67205 11.6213 5.50012C11.6213 5.32818 11.5607 5.12662 11.4003 4.96616C11.3057 4.87162 11.1969 4.81175 11.0883 4.77851Z"
        fill="#141414"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.54925 4.77851C8.4791 4.75681 8.40455 4.74512 8.32727 4.74512C8.24998 4.74512 8.17543 4.75681 8.10528 4.77851C7.99667 4.81175 7.88785 4.87162 7.79331 4.96616C7.63285 5.12662 7.57227 5.32818 7.57227 5.50012C7.57227 5.67205 7.63285 5.87361 7.79331 6.03407C7.95377 6.19453 8.15533 6.25512 8.32727 6.25512C8.4992 6.25512 8.70076 6.19453 8.86122 6.03407C9.02168 5.87361 9.08227 5.67205 9.08227 5.50012C9.08227 5.32818 9.02168 5.12662 8.86122 4.96616C8.76668 4.87162 8.65786 4.81175 8.54925 4.77851Z"
        fill="#141414"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6.00042 4.77363C5.93027 4.75192 5.85572 4.74023 5.77844 4.74023C5.70116 4.74023 5.6266 4.75192 5.55645 4.77363C5.44784 4.80686 5.33903 4.86673 5.24448 4.96128C5.08402 5.12174 5.02344 5.3233 5.02344 5.49523C5.02344 5.66717 5.08402 5.86873 5.24448 6.02919C5.40494 6.18965 5.6065 6.25023 5.77844 6.25023C5.95037 6.25023 6.15193 6.18965 6.31239 6.02919C6.47285 5.86873 6.53344 5.66717 6.53344 5.49523C6.53344 5.3233 6.47285 5.12174 6.31239 4.96128C6.21785 4.86673 6.10903 4.80686 6.00042 4.77363Z"
        fill="#141414"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M5 3.75C4.30921 3.75 3.75 4.30921 3.75 5V19C3.75 19.6908 4.30921 20.25 5 20.25H19C19.6908 20.25 20.25 19.6908 20.25 19V5C20.25 4.30921 19.6908 3.75 19 3.75H5ZM2.25 5C2.25 3.48079 3.48079 2.25 5 2.25H19C20.5192 2.25 21.75 3.48079 21.75 5V19C21.75 20.5192 20.5192 21.75 19 21.75H5C3.48079 21.75 2.25 20.5192 2.25 19V5Z"
        fill="#141414"
        fillRule="evenodd"
      />
    </svg>
  );
};

export { GraphicsSVG };
