import { IllustrationsProps } from '../Illustrations.types';
import { Group } from './PermissionDenied.styles';

export const PermissionDenied: React.FC<IllustrationsProps> = (props) => {
  return (
    <svg fill="none" height="249" viewBox="0 0 210 249" width="210" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M140.445 248.918L84.8315 248.98C82.3727 248.983 80.3807 247.465 80.3786 245.588L80.3172 190.346C80.3152 188.469 82.3039 186.947 84.7626 186.944L140.377 186.883C142.835 186.88 144.827 188.397 144.829 190.274L144.891 245.516C145.01 247.393 143.021 248.915 140.445 248.918Z"
        fill="#EA1D2C"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M111.763 150.896C122.4 150.884 129.864 158.356 129.873 166.315L129.898 188.289C129.901 190.823 131.956 192.874 134.49 192.871C137.023 192.868 139.074 190.813 139.071 188.279L139.047 166.305C139.031 152.289 126.405 141.706 111.753 141.723C97.3566 141.739 84.4973 152.123 84.5132 166.365L84.5376 188.34C84.5404 190.873 86.5962 192.924 89.1294 192.922C91.6626 192.919 93.7139 190.863 93.7111 188.33L93.6867 166.355C93.6777 158.268 101.271 150.908 111.763 150.896Z"
        fill="#EA1D2C"
        fillRule="evenodd"
      />
      <Group>
        <path
          d="M171.97 68.3176C171.97 70.5462 173.78 72.3529 176.013 72.3529C178.246 72.3529 180.057 70.5462 180.057 68.3176C180.057 66.0889 178.246 64.2822 176.013 64.2822C173.78 64.2822 171.97 66.0889 171.97 68.3176Z"
          fill="#FF5F6C"
        />
        <path
          d="M171.97 91.9382C171.97 94.1668 173.78 95.9735 176.013 95.9735C178.246 95.9735 180.057 94.1668 180.057 91.9382C180.057 89.7095 178.246 87.9028 176.013 87.9028C173.78 87.9028 171.97 89.7095 171.97 91.9382Z"
          fill="#FF5F6C"
        />
        <path
          d="M171.97 115.524C171.97 117.753 173.78 119.559 176.013 119.559C178.246 119.559 180.057 117.753 180.057 115.524C180.057 113.295 178.246 111.489 176.013 111.489C173.78 111.489 171.97 113.295 171.97 115.524Z"
          fill="#FF5F6C"
        />
        <path
          d="M147.815 68.3173C147.815 70.546 149.625 72.3527 151.858 72.3527C154.091 72.3527 155.901 70.546 155.901 68.3173C155.901 66.0887 154.091 64.282 151.858 64.282C149.625 64.282 147.815 66.0887 147.815 68.3173Z"
          fill="#FF5F6C"
        />
        <path
          d="M147.815 91.9384C147.815 94.1671 149.625 95.9738 151.858 95.9738C154.091 95.9738 155.901 94.1671 155.901 91.9384C155.901 89.7098 154.091 87.9031 151.858 87.9031C149.625 87.9031 147.815 89.7098 147.815 91.9384Z"
          fill="#FF5F6C"
        />
        <path
          d="M147.815 115.524C147.815 117.753 149.625 119.559 151.858 119.559C154.091 119.559 155.901 117.753 155.901 115.524C155.901 113.295 154.091 111.489 151.858 111.489C149.625 111.489 147.815 113.295 147.815 115.524Z"
          fill="#FF5F6C"
        />
        <path
          d="M123.661 68.3173C123.661 70.546 125.471 72.3527 127.704 72.3527C129.937 72.3527 131.747 70.546 131.747 68.3173C131.747 66.0887 129.937 64.282 127.704 64.282C125.471 64.282 123.661 66.0887 123.661 68.3173Z"
          fill="#FF5F6C"
        />
        <path
          d="M123.661 91.9384C123.661 94.1671 125.471 95.9738 127.704 95.9738C129.937 95.9738 131.747 94.1671 131.747 91.9384C131.747 89.7098 129.937 87.9031 127.704 87.9031C125.471 87.9031 123.661 89.7098 123.661 91.9384Z"
          fill="#FF5F6C"
        />
        <path
          d="M123.661 115.524C123.661 117.753 125.471 119.559 127.704 119.559C129.937 119.559 131.747 117.753 131.747 115.524C131.747 113.295 129.937 111.489 127.704 111.489C125.471 111.489 123.661 113.295 123.661 115.524Z"
          fill="#FF5F6C"
        />
      </Group>
      <path
        d="M103.857 138.242C103.902 137.885 103.766 137.84 103.857 138.242ZM103.857 138.242C104.78 143.618 103.087 148.066 101.443 150.959M103.857 138.242C104.734 142.135 103.844 146.735 101.443 150.959M101.443 150.959C95.8826 157.704 101.134 158.202 103.857 156.222C107.935 153.256 111.452 148.807 109.969 142.135C108.116 138.798 107.004 144.359 110.529 145.156M101.443 150.959C97.612 154.368 94.3144 157.498 93.8322 157.704C91.0265 158.953 92.917 151.104 96.2533 149.178C97.2036 146.323 96.2533 141.275 96.2533 138.242C85.2117 127.937 92.5889 120.175 85.1222 118.792C73.809 116.651 62.9484 112.77 52.2235 108.666C46.1144 106.302 39.598 103.268 36.8376 97.4243C35.4347 94.4355 35.2085 91.0006 35.2537 87.6995C35.4348 78.1977 35.4279 72.2614 39.6816 63.741C31.9887 70.3878 27.8323 74.9412 22.945 83.8185C17.4945 93.7187 17.1605 109.414 25.6227 120.655C35.3973 133.637 64.6121 151.093 71.6715 150.959C94.268 150.959 88.1859 132.824 75.7686 145.156C63.3513 157.489 25.6162 186.466 7.18176 162.741C-0.588414 152.741 0.939383 142.178 16.1817 139.241C36.3849 135.348 82.9914 175.067 35.6818 203.241C21.05 211.954 29.6953 229.172 48.1818 224.741M50.2324 39.8452C71.6715 54.278 64.487 83.0601 57.8348 88.7701C55.3007 90.9114 50.3229 90.5099 50.2324 86.9411C50.1871 85.5136 51.0469 84.2199 52.314 83.4616C54.7124 82.0341 57.4728 82.5248 60.007 83.1493C80.4159 88.0117 100.84 100.76 106.496 120.655C107.324 123.585 107.244 126.47 107.862 129.41"
        stroke="#BC80A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.20566"
      />
      <path
        d="M103.878 138.192C104.436 136.883 105.11 133.933 103.878 132.867C102.338 131.535 101.698 134.515 103.878 138.192Z"
        stroke="#BC80A5"
        strokeWidth="1.20566"
      />
      <path
        d="M107.863 129.41C108.678 133.112 111.008 135.164 113.361 138.152C115.714 141.141 120.44 146.886 122.748 149.919C123.562 150.99 125.056 152.64 124.06 153.533C121.074 156.075 111.273 146.865 110.595 145.572"
        stroke="#BC80A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.20566"
      />
      <path
        d="M97.3657 49.8297C89.763 50.6553 84.9959 49.7232 77.3477 49.459"
        stroke="#BC80A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.20566"
      />
      <path
        d="M85.5029 93.8727C94.0813 93.8727 105.741 93.2962 113.653 89.9201C118.588 87.8147 119.627 80.2382 116.351 82.2676C106.685 88.2546 121.529 109.202 129.441 125.767C130.52 128.018 127.293 138.678 133.119 143.488C136.446 146.234 141.121 146.414 145.392 146.459C163.824 146.639 184.272 146.774 202.704 146.954C210.844 146.954 212.879 136.64 200.669 135.96C183.032 133.242 180.965 133.33 169.681 125.767C161.634 120.365 158.205 109.592 153.439 101.174C148.674 92.756 144.493 83.8881 138.379 76.3707C132.31 68.8532 125.55 64.2383 115.929 63.293C115.336 61.581 116.944 53.9039 118.125 57.6142C119.181 63.7683 107.69 71.4622 100.272 73.4429C96.4958 74.4512 95.4851 71.2606 96.3393 70.0452C102.986 62.5703 111.9 54.8986 113.159 53.1881C113.609 52.6029 113.923 51.8826 114.013 51.1624C114.643 47.5612 111.155 46.2108 108.412 47.1561C106.255 47.9213 104.932 49.3168 103.628 51.2074M103.628 51.2074C103.943 49.5869 103.449 47.7863 101.92 47.1561C100.751 46.6609 98.8181 47.4262 97.829 49.4519C96.7501 51.6126 93.6931 57.9146 93.1087 64.8019C92.9738 66.2874 92.3444 70.0686 93.1087 72.0042C93.5582 73.1296 95.1317 73.1296 95.5813 72.0042L103.628 51.2074Z"
        stroke="#BC80A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.20566"
      />
      <path
        d="M114.182 51.241C121.091 52.5314 122.573 58.5856 122.573 64.6575"
        stroke="#BC80A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.20566"
      />
      <path
        d="M129.987 126.868C129.987 126.868 133.754 133.241 146.181 133.241C160.181 133.241 160.681 125.241 169.181 125.241"
        stroke="#BC80A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.20566"
      />
      <path
        d="M64.373 84.305C71.6431 81.4065 79.9426 72.4424 79.9426 63.5456C84.5277 63.3167 88.7029 63.387 93.2879 63.1582"
        stroke="#BC80A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.20566"
      />
      <path
        d="M110.76 145.828C110.77 144.682 110.153 142.175 108.902 141.751C107.338 141.222 107.989 143.742 110.76 145.828Z"
        stroke="#BC80A5"
        strokeWidth="1.20566"
      />
      <path
        d="M39.6816 63.3666C41.0473 62.9176 43.7787 61.197 43.7787 59.5808C43.7787 57.5606 41.0473 59.3261 39.6816 63.3666Z"
        stroke="#BC80A5"
        strokeWidth="1.20566"
      />
      <path
        d="M75.4948 49.8293C72.2819 53.3981 71.0464 54.6485 69.5996 55.9822"
        stroke="#BC80A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.20566"
      />
      <path
        d="M19.8887 90.6069C19.8887 90.6069 -1.24281 82.4514 0.823901 61.9596C2.67303 43.6252 22.4626 27.7613 39.7338 39.9226C33.234 29.5688 37.08 18.5202 42.8723 10.5351C48.7099 2.5054 57.3298 0.131117 70.6751 1.26734C80.6307 2.11492 89.2229 9.62659 91.938 19.1284C93.7674 25.44 91.0365 29.4814 83.9457 29.5688C83.0859 29.5688 82.0451 29.3457 81.7736 28.5428C81.2758 27.2491 82.8767 26.4913 83.9628 27.3835C85.0488 28.2757 85.8946 29.0701 86.2445 31.665C86.7785 35.6255 85.3485 40.904 84.0362 43.6252C82.7239 46.3464 80.4248 49.1019 77.3476 49.4588C74.3798 49.803 71.4164 48.3467 70.3042 46.8639"
        stroke="#BC80A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.20566"
      />
      <path
        d="M77.6812 16.741C95.3479 5.61985 104.681 31.665 86.6812 35.0015C82.4529 35.7852 75.1141 36.3926 72.2171 33.9548C68.4548 30.7888 68.5322 25.7339 71.4183 25.7339C74.825 25.7339 71.6435 35.7571 69.6227 39.45C69.5392 38.3378 70.9818 35.0015 67.2838 35.0015C64.9493 35.0015 63.3927 36.7765 62.3971 38.3379C60.1798 41.9958 62.948 43.2239 63.4005 43.2239C63.4005 43.2239 64.604 42.04 65.8554 41.3035C69.6343 39.0792 72.1572 42.4156 68.0794 45.3812C62.8896 48.7175 62.2692 44.8745 63.4458 43.1793"
        stroke="#BC80A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.20566"
      />
      <path
        d="M75.4939 44.3092C77.2953 43.4887 76.9987 43.5806 77.9695 44.3092C79.4492 43.6335 79.6848 44.1568 79.9421 44.6395C79.5714 45.7516 78.8301 46.8637 77.718 46.8637C76.2373 46.705 75.3009 44.7919 75.4939 44.3092Z"
        stroke="#BC80A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.20566"
      />
      <circle cx="75.8645" cy="40.9326" fill="#BC80A5" r="0.741407" />
      <circle cx="80.3137" cy="40.9326" fill="#BC80A5" r="0.741407" />
    </svg>
  );
};
