import { IllustrationsProps } from '../Illustrations.types';

const EmptySearch: React.FC<IllustrationsProps> = (props) => (
  <svg {...props} fill="none" height="270" viewBox="0 0 252 270" width="252" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M83.1289 182.553C90.6618 185.55 90.6618 196.312 90.6618 196.312M90.6618 196.312C90.6618 196.312 89.5857 203.845 86.7416 201.539C84.5894 199.848 88.4327 195.927 90.6618 196.312ZM90.6618 196.312C92.891 196.696 118.801 199.852 134.174 216.379C149.547 232.905 147.774 231.517 151.31 236.129C154.846 240.741 143.976 238.128 139.856 234.669C140.014 234.982 136.396 231.228 138.576 229.878C140.756 228.528 160.272 256.026 138.576 267.276C136.176 267.726 131.377 267.906 131.377 263.226C131.377 258.546 140.064 247.927 157.123 259.176C196.721 286.625 225.969 253.326 209.77 208.611C193.571 163.895 234.069 152.081 232.719 164.681C231.639 174.76 190.226 166.481 185.276 164.681C150.261 151.948 172 129.5 188 120.5C204 111.5 237.887 84.5852 232.719 62.5359C225.969 33.7373 184.121 23.9784 165.222 45.1221M125.174 238.922C123.319 227.566 131.247 230.222 125.174 238.922ZM125.174 238.922C125.283 242.279 127.424 255.571 130.479 245.814C132.016 241.817 133.169 236.129 131.377 237.102C126.602 239.692 130.989 262.122 136.049 243.502M125.174 238.922C120.366 258.323 117.525 236.129 118.875 229.878C116.197 226.932 113.317 221.863 108.649 222.447C96.8112 224.138 53.5351 228.903 52.3052 208.611C50.9216 185.012 79.7467 183.244 79.7467 183.244C79.7467 183.244 78.9781 179.247 83.5132 179.094C88.1252 178.94 96.8257 179.094 96.8257 179.094M165.222 45.1221C149.079 -42.9837 16.4404 14.8399 90.6618 96.5163C123.374 132.515 74.7916 191.062 41.9283 153.936M165.222 45.1221C167.836 59.386 162.46 63.2939 158.923 62.5359C152.623 61.1859 157.123 48.5918 165.222 45.1221ZM41.9283 153.936C32.4515 145.836 34.7478 115.633 48.7765 120.516C61.1006 124.806 57.9802 144.487 41.9283 153.936ZM41.9283 153.936C0.276474 164.681 -20.6981 86.5151 33.7765 59.5164C96.8257 28.2679 158.923 120.516 139.856 134.516C134.458 137.376 127.696 132.433 134.174 115.633C142.271 94.6331 180.418 51.8904 223.776 78.0164C262.777 101.516 242.5 141.5 218.5 149"
      stroke="#BC80A5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
    />
    <path
      d="M108.525 184.474C106.418 183.502 103.618 181.985 102.675 179.525C103.036 180.466 99.4691 182.236 97.2757 179.525C96.5309 178.604 95.5962 176.287 95.0258 174.125C93.7995 169.476 99.8764 166.475 102.225 172.775C103.057 175.004 99.7956 176.735 99.0756 172.775C98.1756 167.825 97.2757 160.516 104.277 160.516C111.086 160.516 130.5 166.107 130.5 151.5C130.5 134 108.525 127 115.5 123C122.475 119 126.5 142.5 108.525 142.5"
      stroke="#BC80A5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M125.526 163.305C126.814 162.446 132.276 168.016 137.776 176.516C147.225 183.686 172.245 177.725 184.311 186.885C192.531 193.125 194.492 211.045 178.742 211.945C160.699 212.976 146.287 193.203 134.323 193.004"
      stroke="#BC80A5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
    />
    <circle cx="110.627" cy="170.33" fill="#BC80A5" r="1.34993" />
    <circle cx="117.326" cy="167.866" fill="#BC80A5" r="1.34993" />
    <path
      d="M121.477 190.121C114.278 190.121 111.128 188.742 114.278 186.717C114.278 186.717 115.969 186.741 119.494 186.004M121.477 190.121C131.024 192.574 129.224 186.253 121.477 190.121ZM121.477 190.121C117.428 189.995 116.175 189.683 115.275 189.595C116.355 191.395 125.078 194.867 130.028 193.453C132.128 192.853 137.441 192.453 140.69 194.774M125.527 163.304C121.027 166.454 130.292 171.216 125.527 177.516C122.464 181.566 106.628 182.653 107.978 186.253C110.664 187.002 114.278 187.094 119.494 186.004M119.494 186.004C121.066 185.675 125.187 182.231 126.074 184.474C126.962 186.717 121.613 185.746 119.494 186.004Z"
      stroke="#BC80A5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <rect height="80.058" rx="4.5" stroke="#E8B9D6" width="114.867" x="136.23" y="123.996" />
    <path
      clipRule="evenodd"
      d="M245.553 133.503C245.553 130.37 245.5 129 240.948 129.002L145.995 129.002L145.696 129.006C141.608 129.059 140.489 129.073 140.029 132.124L140.029 177.547V192.606C139.88 195.034 140.075 197 146.201 197H240.948C244.112 196.654 245.553 195.29 245.553 192.606V133.503ZM222.297 135.81C222.809 135.142 224.048 134.86 224.846 135.371C225.643 135.882 226.029 136.904 225.44 137.905C224.928 138.573 223.732 138.947 222.934 138.435C222.007 138.058 221.787 136.609 222.297 135.81ZM231.846 135.371C231.049 134.86 229.81 135.142 229.298 135.81C228.788 136.609 229.008 138.058 229.935 138.435C230.733 138.947 231.929 138.573 232.441 137.905C233.03 136.904 232.644 135.882 231.846 135.371ZM236.298 135.81C236.81 135.142 238.049 134.86 238.846 135.371C239.644 135.882 240.03 136.904 239.441 137.905C238.929 138.573 237.733 138.947 236.935 138.435C236.008 138.058 235.788 136.609 236.298 135.81Z"
      fill="#E8B9D6"
      fillRule="evenodd"
    />
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M53.2713 141.957C52.0428 141.957 51.047 140.961 51.047 139.732V139.732C51.047 128.485 53.6691 123.783 62.8011 115.682C68.425 110.727 71.6799 106.803 71.6799 100.926C71.6799 94 66.8517 89.1537 60.0524 89.1537C55.9765 89.1537 52.609 90.9407 50.5652 93.8915C48.1398 97.393 44.972 101.179 40.7125 101.179V101.179C36.453 101.179 32.8817 97.663 33.9906 93.5504C37.1115 81.9755 47.3023 74 60.1971 74C76.0199 74 87.2496 85.6275 87.2496 100.528C87.2496 111.902 81.7523 117.906 73.2712 125.483C66.9964 131.107 65.1519 133.982 65.1519 139.732V139.732C65.1519 140.961 64.1561 141.957 62.9277 141.957H53.2713ZM58.1176 151.776C63.868 151.776 68.0633 156.08 68.0633 161.703C68.0633 167.327 63.8861 171.649 58.1176 171.649C52.3671 171.649 48.1899 167.327 48.1899 161.703C48.1899 156.08 52.3671 151.776 58.1176 151.776Z"
        fill="#FF5F6C"
      />
    </g>
  </svg>
);

export { EmptySearch };
