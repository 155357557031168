import { IllustrationsProps } from '../Illustrations.types';

const Balloon: React.FC<IllustrationsProps> = () => (
  <svg fill="none" height="322" viewBox="0 0 193 322" width="193" xmlns="http://www.w3.org/2000/svg">
    <path d="M100.435 274.964L118.26 264.163V310.199L100.435 321" fill="white" />
    <path
      d="M100.435 274.964L118.26 264.163V310.199L100.435 321"
      stroke="black"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      d="M69.498 264.163H139.554V284.909H69.498V264.163Z"
      fill="white"
      stroke="black"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path d="M121.73 274.964L139.554 264.163V310.199L121.73 321" fill="#DBDBDB" />
    <path
      d="M121.73 274.964L139.554 264.163V310.199L121.73 321"
      stroke="black"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      d="M51.6733 274.964H121.729V321H51.6733V274.964Z"
      fill="white"
      stroke="black"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      d="M69.4958 264.255L51.5883 274.988H121.645L139.569 264.255H69.4958Z"
      fill="white"
      stroke="black"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <g clipPath="url(#clip0_6037_176424)">
      <rect fill="#EA1D2C" height="45.9367" transform="translate(60.9312 275.063)" width="3.89294" />
      <path d="M64.7747 293.077H61.041V320.875H64.7747V293.077Z" fill="#EA1D2C" />
    </g>
    <g clipPath="url(#clip1_6037_176424)">
      <rect fill="#EA1D2C" height="45.9367" transform="translate(109.204 275.063)" width="3.89294" />
      <path d="M113.048 293.077H109.314V320.875H113.048V293.077Z" fill="#EA1D2C" />
    </g>
    <path d="M77.5868 264.163L60.9312 275.063H64.8241L81.1745 264.163H77.5868Z" fill="#EA1D2C" />
    <path d="M125.165 264.163L109.204 275.063H113.097L129.058 264.163H125.165Z" fill="#EA1D2C" />
    <path d="M78.0601 264.942L46.9165 101.438" stroke="#DBDBDB" strokeLinejoin="round" strokeMiterlimit="10" />
    <path d="M125.943 264.552L159.423 105.72" stroke="#DBDBDB" strokeLinejoin="round" strokeMiterlimit="10" />
    <path
      d="M47.0191 254.144L46.3463 255.379C45.654 256.64 44.6154 257.678 43.3547 258.371L42.1202 259.043C41.983 259.115 41.983 259.318 42.1202 259.39L43.3547 260.062C44.6154 260.755 45.654 261.793 46.3463 263.054L47.0191 264.289C47.091 264.426 47.2935 264.426 47.3653 264.289L48.0447 263.054C48.737 261.793 49.7756 260.755 51.0363 260.062L52.2643 259.39C52.4015 259.318 52.4015 259.115 52.2643 259.043L51.0298 258.371C49.7691 257.678 48.7305 256.64 48.0381 255.379L47.3653 254.144C47.2935 254.007 47.0975 254.007 47.0191 254.144Z"
      fill="#FFC400"
    />
    <path
      d="M52.6258 247.937L52.1452 248.819C51.6506 249.719 50.9088 250.461 50.0083 250.956L49.1265 251.436C49.0285 251.488 49.0285 251.632 49.1265 251.684L50.0083 252.164C50.9088 252.659 51.6506 253.4 52.1452 254.301L52.6258 255.183C52.6771 255.281 52.8217 255.281 52.873 255.183L53.3583 254.301C53.8528 253.4 54.5947 252.659 55.4952 252.164L56.3723 251.684C56.4703 251.632 56.4703 251.488 56.3723 251.436L55.4905 250.956C54.59 250.461 53.8482 249.719 53.3536 248.819L52.873 247.937C52.8217 247.839 52.6818 247.839 52.6258 247.937Z"
      fill="#FFC400"
    />
    <path
      d="M186.885 219.032H136.988C135.057 216.115 135.081 212.369 136.988 209.735C139.271 206.581 143.858 205.576 147.583 207.669C147.972 199.602 154.056 192.915 161.936 191.831C169.94 190.729 177.681 195.697 180.087 203.537C185.907 202.274 191.047 205.999 191.669 210.424C192.12 213.619 190.2 217.097 186.885 219.032Z"
      stroke="#A6A6A6"
      strokeMiterlimit="10"
    />
    <path
      d="M39.7463 275.217H3.46493C0.959855 273.535 0.283908 270.159 1.80893 267.78C3.19094 265.624 6.0784 264.76 8.55185 265.883C8.63766 265.288 9.30458 261.142 12.9493 258.978C16.3847 256.938 21.0456 257.503 24.3907 260.496C26.1867 257.171 30.1235 255.885 33.1223 257.309C35.5792 258.474 37.0817 261.302 36.7354 264.29C39.8261 264.024 42.5615 266.153 43.0583 268.995C43.4919 271.47 42.152 274.056 39.7463 275.217Z"
      stroke="#DCDCDC"
      strokeMiterlimit="10"
    />
    <path d="M64.8241 273.895L32.5127 102.995" stroke="#DBDBDB" strokeLinejoin="round" strokeMiterlimit="10" />
    <path d="M112.708 273.506L141.126 135.307" stroke="#DBDBDB" strokeLinejoin="round" strokeMiterlimit="10" />
    <path
      d="M82.2488 179.285C80.297 179.285 78.457 178.421 77.276 176.961C54.6247 148.64 19.1187 99.9484 19.1187 72.9115C19.1311 33.1968 53.4064 1 95.7003 1C137.994 1 172.27 33.1968 172.27 72.8999C172.27 99.9367 136.763 148.629 114.112 176.95C112.931 178.421 111.104 179.273 109.139 179.273H82.2488V179.285Z"
      fill="white"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M97.525 1C122.202 23.6171 128.87 44.9375 131.102 55.9423C135.131 75.9076 130.484 91.7489 121.276 121.878C113.864 146.119 105.035 165.675 98.1427 179.297"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M90.5176 1C57.066 23.6171 48.0275 44.9375 45.0021 55.9423C39.541 75.9076 45.8393 91.7489 58.3218 121.878C68.3687 146.119 80.3375 165.675 89.6803 179.297"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M82.3977 179.284H108.816C110.484 179.284 111.837 180.555 111.837 182.121V184.246C111.837 185.813 110.484 187.083 108.816 187.083H82.3977C80.7293 187.083 79.3767 185.813 79.3767 184.246V182.121C79.3767 180.555 80.7293 179.284 82.3977 179.284Z"
      fill="white"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      clipRule="evenodd"
      d="M73.2951 86.9251C79.896 86.9251 84.4745 80.5889 84.4745 75.4134C84.4745 71.648 81.0905 70 77.8727 70C70.6287 70 66.687 76.8208 66.687 81.5126C66.687 85.2789 70.1067 86.9251 73.2951 86.9251Z"
      fill="#EA1D2C"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M91.0862 86.9251C97.6836 86.9251 102.262 80.5889 102.262 75.4134C102.262 71.648 98.8718 70 95.655 70C88.4208 70 84.4746 76.8208 84.4746 81.5126C84.4746 85.2789 87.8996 86.9251 91.0862 86.9251Z"
      fill="#EA1D2C"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M94.5484 97.9122C90.4013 101.46 84.856 103.291 78.8314 102.91C71.2334 102.441 65.9648 96.5226 64.9082 90.5186H65.2666C66.9751 94.3526 71.1365 97.89 76.3563 98.4716C81.4542 99.0382 87.7011 96.7203 91.1288 93.4137L87.2876 90.4883L98.7045 90.5195L96.2463 102.681L94.5476 97.9122H94.5484Z"
      fill="#EA1D2C"
      fillRule="evenodd"
    />
    <defs>
      <clipPath id="clip0_6037_176424">
        <rect fill="white" height="45.9367" transform="translate(60.9312 275.063)" width="3.89294" />
      </clipPath>
      <clipPath id="clip1_6037_176424">
        <rect fill="white" height="45.9367" transform="translate(109.204 275.063)" width="3.89294" />
      </clipPath>
    </defs>
  </svg>
);

export { Balloon };
