import { IllustrationsProps } from '../Illustrations.types';

const Unexpected: React.FC<IllustrationsProps> = (props) => (
  <svg fill="none" height="304" viewBox="0 0 319 304" width="319" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path
        d="M108.098 213.236C108.098 217.114 111.247 220.257 115.133 220.257C119.018 220.257 122.168 217.114 122.168 213.236C122.168 209.358 119.018 206.214 115.133 206.214C111.247 206.214 108.098 209.358 108.098 213.236Z"
        fill="#FF5F6C"
      />
      <path
        d="M108.098 254.336C108.098 258.214 111.247 261.358 115.133 261.358C119.018 261.358 122.168 258.214 122.168 254.336C122.168 250.459 119.018 247.315 115.133 247.315C111.247 247.315 108.098 250.459 108.098 254.336Z"
        fill="#FF5F6C"
      />
      <path
        d="M108.098 295.376C108.098 299.254 111.247 302.397 115.133 302.397C119.018 302.397 122.168 299.254 122.168 295.376C122.168 291.498 119.018 288.354 115.133 288.354C111.247 288.354 108.098 291.498 108.098 295.376Z"
        fill="#FF5F6C"
      />
      <path
        d="M77.3575 213.236C77.3575 217.114 80.5073 220.258 84.3928 220.258C88.2782 220.258 91.428 217.114 91.428 213.236C91.428 209.358 88.2782 206.215 84.3928 206.215C80.5073 206.215 77.3575 209.358 77.3575 213.236Z"
        fill="#FF5F6C"
      />
      <path
        d="M77.3573 254.336C77.3573 258.214 80.5071 261.357 84.3925 261.357C88.278 261.357 91.4277 258.214 91.4277 254.336C91.4277 250.458 88.278 247.314 84.3925 247.314C80.5071 247.314 77.3573 250.458 77.3573 254.336Z"
        fill="#FF5F6C"
      />
      <path
        d="M77.3573 295.375C77.3573 299.252 80.5071 302.396 84.3925 302.396C88.278 302.396 91.4277 299.252 91.4277 295.375C91.4277 291.497 88.278 288.353 84.3925 288.353C80.5071 288.353 77.3573 291.497 77.3573 295.375Z"
        fill="#FF5F6C"
      />
      <path
        d="M46.6193 213.236C46.6193 217.113 49.769 220.257 53.6545 220.257C57.5399 220.257 60.6897 217.113 60.6897 213.236C60.6897 209.358 57.5399 206.214 53.6545 206.214C49.769 206.214 46.6193 209.358 46.6193 213.236Z"
        fill="#FF5F6C"
      />
      <path
        d="M46.619 254.336C46.619 258.214 49.7688 261.358 53.6542 261.358C57.5397 261.358 60.6895 258.214 60.6895 254.336C60.6895 250.458 57.5397 247.315 53.6542 247.315C49.7688 247.315 46.619 250.458 46.619 254.336Z"
        fill="#FF5F6C"
      />
      <path
        d="M46.619 295.375C46.619 299.253 49.7688 302.397 53.6542 302.397C57.5397 302.397 60.6895 299.253 60.6895 295.375C60.6895 291.497 57.5397 288.354 53.6542 288.354C49.7688 288.354 46.619 291.497 46.619 295.375Z"
        fill="#FF5F6C"
      />
    </g>
    <path
      clipRule="evenodd"
      d="M271.791 133.406L276.597 68.5463C276.429 67.2829 277.799 66.3575 278.921 66.9857L300.862 79.3876C301.917 79.9664 303.223 79.1821 303.195 77.9807L314.7 29.9533C314.67 28.1436 317.241 27.7269 317.762 29.4495L318.681 101.705C319.102 103.145 317.442 104.306 316.256 103.401L289.239 86.3916C288.337 85.7059 287.005 86.2197 286.766 87.3186L274.856 133.51C274.486 135.214 272.025 135.136 271.791 133.406Z"
      fill="#FF5F6C"
      fillRule="evenodd"
    />
    <path
      d="M305.851 221.592H253.724C248.734 221.592 244.635 217.492 244.635 212.501V154.121C244.635 149.13 248.734 145.03 253.724 145.03H305.851C310.841 145.03 314.939 149.13 314.939 154.121V212.501C314.939 217.492 310.93 221.592 305.851 221.592Z"
      stroke="#C490B0"
      strokeMiterlimit="10"
      strokeWidth="1.5"
    />
    <path
      clipRule="evenodd"
      d="M279.832 206.084C267.535 206.084 257.467 196.102 257.467 183.713V182.821C257.467 170.521 267.446 160.45 279.832 160.45C292.129 160.45 302.197 170.432 302.197 182.821V183.713C302.108 196.102 292.129 206.084 279.832 206.084ZM289.455 187.455C287.227 187.455 285.445 185.673 285.445 183.444V183.266C285.445 181.038 287.227 179.255 289.455 179.255C291.682 179.255 293.465 181.038 293.465 183.266V183.444C293.465 185.583 291.682 187.455 289.455 187.455ZM264.951 183.444C264.951 185.673 266.733 187.455 268.96 187.455C271.099 187.455 272.97 185.583 272.97 183.444V183.266C272.97 181.038 271.188 179.255 268.96 179.255C266.733 179.255 264.951 181.038 264.951 183.266V183.444Z"
      fill="#E8B9D6"
      fillRule="evenodd"
    />
    <path
      d="M1.04732 48.2844L58.5454 84.5947C64.7739 89.0118 71.9242 94.3055 75.5462 101.068L106.174 158.617C110.383 173.53 131.603 166.738 133.344 155.892C133.981 151.812 134.445 147.55 132.973 144.568L137.262 145.539C139.509 146.048 141.91 145.876 143.988 144.915C148.062 143.045 150.017 138.834 149.541 134.932M149.541 134.932C149.541 134.932 162.338 134.68 157.39 122.392C161.082 119.362 162.352 112.49 157.368 109.214L148.089 103.158C149.429 95.6584 149.265 89.052 146.97 81.8028C142.911 69.0712 145.69 57.7449 145.327 48.2844C144.062 14.9947 120.766 14.5165 107.158 17.0907C100.225 18.3129 80.6379 12.6592 70.5305 1.69348M149.541 134.932C149.541 134.932 147.647 128.758 142.553 127.605"
      stroke="#BC80A5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
    />
    <path
      d="M78.2175 110.014C52.5626 146.535 132.085 131.215 145.436 77.8444C150.742 56.6371 168.364 45.6025 190.575 53.4839C212.786 61.3652 208.035 97.9924 208.035 97.9924C207.538 102.415 204.353 113.782 195.595 112.403C184.647 110.68 185.742 65.7492 229.982 60.6488"
      stroke="#BC80A5"
      strokeWidth="1.5"
    />
    <path
      d="M78.5285 108.765C51.5853 127.608 13.6064 111.578 60.3442 88.2086C117.269 59.7459 170.241 60.5366 146.523 80.3023"
      stroke="#BC80A5"
      strokeWidth="1.5"
    />
    <path
      d="M86.5099 124.742C44.9514 150.102 80.0188 166.914 110.061 150.958C140.992 134.53 165.1 171.277 165.1 212.29M165.1 212.29C159.074 224.273 140.796 236.594 133.201 227.48C123.167 215.439 145.411 207.832 165.1 212.29ZM165.1 212.29L203.325 298.59"
      stroke="#BC80A5"
      strokeWidth="1.5"
    />
    <path d="M132.731 144.278C138.782 137.585 148.36 115.314 148.252 102.439" stroke="#BC80A5" strokeWidth="1.5" />
    <path d="M146.76 115.707C149.319 115.531 157.219 119.588 158.63 126.71" stroke="#BC80A5" strokeWidth="1.5" />
    <path
      d="M204.913 275.72L203.754 176.341C203.754 173.667 205.804 171.528 208.388 171.439C210.972 171.439 213.11 173.578 213.2 176.163L214.358 276.166C214.447 276.255 205.893 276.701 204.913 275.72Z"
      fill="#E8B9D6"
    />
    <path
      d="M155.182 270.015L155.49 302.469L188.372 302.614L264.83 303.008L264.955 281.17C264.972 275.287 260.335 270.479 254.631 270.466L155.182 270.015Z"
      fill="#E8B9D6"
    />
    <path
      d="M74.9116 279.538L74.7672 302.445L205.225 303.185L205.341 281.882C205.362 275.732 200.55 270.742 194.58 270.724L84.35 270.08C79.1811 270.076 74.917 274.278 74.9116 279.538Z"
      fill="#E8B9D6"
    />
    <path
      d="M98.5345 296.577L90.6042 296.666C89.9804 296.666 89.4458 296.131 89.4458 295.507V292.566C89.4458 291.942 89.9804 291.407 90.6042 291.407L98.5345 291.318C99.1583 291.318 99.6929 291.853 99.6929 292.477V295.418C99.6929 296.042 99.1583 296.577 98.5345 296.577Z"
      fill="white"
    />
    <path
      d="M121.12 297.022L113.19 297.111C112.566 297.111 112.032 296.577 112.032 295.953V293.011C112.032 292.387 112.566 291.853 113.19 291.853L121.12 291.764C121.744 291.764 122.279 292.298 122.279 292.922V295.863C122.279 296.487 121.833 297.022 121.12 297.022Z"
      fill="white"
    />
    <path
      d="M273.428 34.5593L260.414 39.4455C258.245 40.2598 255.822 39.1118 255.007 36.9425C254.192 34.7733 255.278 32.2856 257.382 31.5322L270.396 26.6461C272.565 25.8317 274.988 26.9798 275.803 29.1491C276.683 31.2573 275.597 33.745 273.428 34.5593Z"
      fill="#E8B9D6"
    />
    <path
      d="M280.95 54.1519L267.936 59.038C265.767 59.8524 263.344 58.7043 262.529 56.535C261.714 54.3658 262.8 51.8781 264.904 51.1248L277.918 46.2386C280.087 45.4243 282.51 46.5723 283.325 48.7416C284.14 50.9108 283.119 53.3375 280.95 54.1519Z"
      fill="#E8B9D6"
    />
    <path
      d="M244.921 77.2038L262.147 70.685C266.867 68.9422 269.178 63.5929 267.308 58.8686L256.347 30.2785C254.832 26.3219 250.482 24.2935 246.591 25.7472L227.833 32.8483C215.778 37.3233 209.803 51.0054 214.464 63.2572C219.316 75.4519 232.801 81.7398 244.921 77.2038Z"
      fill="#E8B9D6"
    />
    <path
      d="M227.949 37.2644L247.344 29.9312C249.066 29.292 250.932 30.1073 251.701 31.7077C252.329 33.808 251.247 36.1697 249.143 36.923L230.451 43.9631L230.386 44.0241C226.239 45.5958 223.672 38.9581 227.949 37.2644C227.884 37.3254 227.884 37.3254 227.949 37.2644Z"
      fill="white"
    />
  </svg>
);

export { Unexpected };
