import * as InternalIllustrations from './src/Illustrations/Illustrations';

export * as Animations from './src/Animations';

export { Icons } from '@ifood/fits-crafts';

export const Illustrations = { ...InternalIllustrations };

export * as LogoIfoodBeneficios from './src/Logos/IfoodBeneficios';
export * as LogoIfoodBeneficiosShort from './src/Logos/IfoodBeneficiosShort';

export * as ChatSVG from './src/Icons/Chat';
export * as HelpSVG from './src/Icons/Help';
export * as GraphicsSVG from './src/Icons/Graphics';

export * as IconSupport from './src/Icons/Support';
