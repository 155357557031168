import { AnimationContainer } from './Animations.styles';
import { AnimationsProps } from './Animations.types';

const DEFAULT_OPTIONS = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Animations: React.FC<AnimationsProps> = ({ animation, height, width, options = DEFAULT_OPTIONS, ...props }) => {
  return (
    <AnimationContainer
      animationData={animation}
      height={height}
      loop={options.loop}
      play={options.autoplay}
      rendererSettings={options.rendererSettings}
      width={width}
      {...props}
    />
  );
};

export { Animations };
