import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import styled from 'styled-components';

import { ResizeableComponent } from './Animations.types';

const getSize = (size?: number) => (size ? `${size}px` : 'auto');

export const AnimationContainer = styled(Lottie)<ResizeableComponent>`
  height: ${(props) => getSize(props.height)};
  width: ${(props) => getSize(props.width)};
`;
