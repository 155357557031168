import { IllustrationsProps } from '../Illustrations/Illustrations.types';

type Props = {
  fill2?: string;
};

const IfoodBeneficiosShortSVG: React.FC<IllustrationsProps & Props> = ({
  className,
  fill = 'none',
  fill2 = '#69022D',
  height = 24,
  viewBox = '0 0 24 24',
  width = 24,
  xmlns = 'http://www.w3.org/2000/svg',
  ...otherProps
}) => {
  return (
    <svg
      className={className}
      fill={fill}
      height={height}
      viewBox={viewBox}
      width={width}
      xmlns={xmlns}
      {...otherProps}
    >
      <path
        d="M8.35663 1.34302C10.3798 1.34302 12.5086 2.38702 12.5086 4.77457C12.5086 8.05273 9.62901 12.0721 5.47854 12.0721C3.47468 12.0721 1.32422 11.0281 1.32422 8.64058C1.32422 5.66438 3.80184 1.34302 8.35663 1.34302Z"
        fill={fill2}
      />
      <path
        d="M19.5365 22.7021L18.4833 19.5846C15.9123 21.9055 12.4763 23.0964 8.74096 22.8499C4.03186 22.5431 0.767193 18.6771 0.111328 14.7565H0.332779C1.39219 17.2621 3.9709 19.5717 7.20778 19.9524C10.3675 20.3218 14.2394 18.808 16.3644 16.6469L13.9817 14.7356L21.0604 14.7573L19.5365 22.7021Z"
        fill={fill2}
      />
      <path
        d="M16.5668 12.0721C14.5629 12.0721 12.4102 11.0281 12.4102 8.64058C12.4102 5.66518 14.8901 1.34302 19.4418 1.34302C21.4642 1.34302 23.5969 2.38702 23.5969 4.77457C23.5969 8.05273 20.7173 12.0721 16.5668 12.0721Z"
        fill={fill2}
      />
    </svg>
  );
};

export { IfoodBeneficiosShortSVG };
